@import "upstairs/static_src/styles/_variables";
@import "upstairs/static_src/styles/_placeholders";
@import "upstairs/static_src/styles/_mixins";

main .blocks {
  padding-bottom: 3rem;
}

.home-banner {
  &__overlay {
    z-index: 2;

    h1 {
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.75);
      @include mediaQuery($min, $xl) {
        font-size: 3.75vw;
      }
    }
  }
}

.home-blocks {
  transform: translateY(-33.33%);
  z-index: 5;
  position: relative;
}

@media (max-width: 580px) {
  .home-blocks {
    transform: translateY(0);
  }
}

.home-block {
  margin: -1px;
  transition: border 0.35s;

  &__icon {
    font-size: 2.5vw;
    opacity: 0.5;
    transition: opacity 0.35s;
    color: $green;
  }

  &__title {
    font-weight: 700;
  }

  &:hover {
    // border-color: $brand !important;

    .home-block__icon {
      opacity: 1;
    }
  }
}

@media (min-width: 581px) and (max-width: 1024px) {
  .home-block__icon {
    font-size: 4vw;
  }
}

@media (max-width: 580px) {
  .home-block__icon {
    font-size: 7vw;
  }
}
//and tablet
